@import 'material-icons/iconfont/material-icons.scss';
@import "/src/styles/brand";
@import "/src/styles/material";
@import "/src/styles/nextpage";
@import "/src/styles/modules/modules-dir";
@import "/src/styles/charts/charts-dir";

/******************************************************************************
    Colors
******************************************************************************/
:root {
    --primary: var(--blue-dark);
    --secondary: var(--blue);
    --font: var(--gray-dark);
    --border: var(--gray-light);
    --border-dark: var(--gray-dark);
    --border-blue: var(--blue-dark);
    --hover-blue: rgb(228, 246, 253);
}

/******************************************************************************
    Icons
******************************************************************************/
.icon {
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    font-display: swap;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;

    &.cursor {
        cursor: pointer;
    }

    &.red {
        color: var(--red);
    }

    &.blue {
        color: var(--blue);
    }

    &.green {
        color: var(--green);
    }
}


/******************************************************************************
    Global
******************************************************************************/
* {
    font-family: var(--font-text);
    cursor: default;

    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--font);
    line-height: 1.5;
}


/******************************************************************************
    Text
******************************************************************************/
h1 {
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

h2 {
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

h3 {
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

p {
    padding: 0;
    margin: 0;
}


/******************************************************************************
    Hyperlink
******************************************************************************/
a {
    cursor: pointer;
    color: var(--blue);
    text-decoration: none;

    &:hover {
        opacity: 0.8;
        text-decoration: underline;
    }
}


/******************************************************************************
    Input
******************************************************************************/
input {
    font-size: 14px;
    border: 1px solid var(--border);
    padding: 0;
    margin: 0;
    margin-right: 5px;
}

select {
    height: 30px;
}

label {
    font-size: 12px;
    font-weight: bold;
    display: block;
}

iframe {
    border: none;
}


/******************************************************************************
    Button
******************************************************************************/
button {
    background: var(--secondary);
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: inherit;
    text-decoration: none;
    padding: 8px 16px;
    margin: 0 5px;
    display: inline-flex;
    border: none;

    &.orange {
        background: var(--orange);
    }

    &.green {
        background: var(--green);
    }

    &.red {
        background: var(--red);
    }

    &.cancel {
        background: var(--gray);
    }

    &.delete {
        background: transparent;
        color: var(--red);
        width: auto;
    }

    &.small {
        padding: 4px 8px;
    }

    &.font {
        font-family: 'Courier New', Courier, monospace;
    }

    :disabled {
        opacity: 0.5;
        cursor: auto;
    }
}

.button:hover,
button:hover {
    opacity: 0.8;
}

.button:disabled,
button:disabled {
    opacity: 0.5;
    cursor: auto;
}

/******************************************************************************
    Table
******************************************************************************/
.table-x {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: white;
    cursor: default;

    .pointer,
    .pointer * {
        cursor: pointer;
    }

    thead {
        tr {
            th {
                position: sticky;
                position: -webkit-sticky;
                top: 0;

                padding: 0.5rem;
                vertical-align: middle;
                text-align: center;
                white-space: nowrap;
                font-weight: 600;
                color: #495057;
                border: 1px solid var(--border-dark);
                background-color: var(--gray)
            }

            td {
                padding: 0.5rem;
                vertical-align: middle;
                text-align: center;
                border: 1px solid var(--border-dark);
            }
        }
    }

    tbody {
        &.alternate {
            tr:nth-child(odd) {
                background-color: var(--gray);

                &:hover {
                    background-color: rgb(228, 246, 253);
                }
            }

            tr:nth-child(odd) td {
                border: 1px solid var(--border-dark);

                &:hover {
                    background-color: rgb(228, 246, 253);
                }
            }
        }

        tr {
            th {
                font-family: 'GothamBold', sans-serif;
                padding: 0.5rem;
                border: 1px solid var(--border);
                background-color: rgb(240, 240, 240);
                color: var(--blue-dark);
                text-align: left;
                white-space: nowrap;
            }

            td {
                padding: 0.5rem;
                vertical-align: middle;
                text-align: left;
                border: 1px solid var(--border);
                width: 100%;
            }

            &:hover {
                background-color: rgb(228, 246, 253);
            }
        }
    }

    tfoot {
        tr {
            th {
                position: sticky;
                position: -webkit-sticky;
                bottom: 0;

                padding: 0.5rem;
                vertical-align: middle;
                text-align: center;
                white-space: nowrap;
                color: var(--gray-dark);
                font-weight: 600;
                border: 1px solid var(--border-dark);
                background-color: var(--gray);
            }

            td {
                padding: 0.5rem;
                vertical-align: middle;
                text-align: center;
                border: 1px solid var(--border-dark);
            }
        }
    }
}


/******************************************************************************
    Custom
******************************************************************************/
.hidden {
    display: none;
}

.block {
    display: inline-block;
}

.inline {
    display: inline-flex;
}

.show {
    visibility: visible;
}

.hide {
    visibility: hidden;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left !important;
}

.bold {
    font-weight: 600;
}

.light {
    font-weight: 300;
}

.nowrap {
    white-space: nowrap;
}

.selected,
.selected * {
    color: var(--blue);
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.required {
    &.label {
        color: var(--red) !important;
    }
    
    &.value {
        // border: 1px solid var(--red) !important;
        // background-color: rgb(255, 250, 250) !important;
    }
    
    &.text {
        // background-color: rgb(255, 250, 250) !important;
    }
}

/******************************************************************************
*	Mobile/Tablet
******************************************************************************/
@media screen and (max-width: 750px) {
}
