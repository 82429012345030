@use "@angular/material" as mat;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$dark-primary-text: rgba(black, 0.87);;
$light-primary-text: white;

$primary-medium-blue-palette: (
  50: #e3f2fb,
  100: #baddf6,
  200: #8fc9f0,
  300: #64b3ea,
  400: #43a4e6,
  500: #1f95e3,
  600: #1687d6,
  700: #0a76c4,
  800: #0065b2,
  900: #004893,
  A100: #0B2D71,
  A200: #009DD9,
  A300: #000000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$accent-medium-purple-palette: (
  50: #f7e4f2,
  100: #ebbbe0,
  200: #e28fcb,
  300: #dc62b5,
  400: #da3da2,
  500: #dc028e,
  600: #ca0a88,
  700: #b30f7f,
  800: #9c1177,
  900: #751269,
  A100: #3A0D36,
  A200: #BA3093,
  A300: #000000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$warning-medium-red-palette: (
  50: #ffebf0,
  100: #ffcdd6,
  200: #f7999f,
  300: #f07079,
  400: #fc4a58,
  500: #ff313d,
  600: #f4263d,
  700: #e21836,
  800: #d50a2f,
  900: #c70022,
  A100: #58001C,
  A200: #E21836,
  A300: #000000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$ccms-primary: mat.define-palette($primary-medium-blue-palette, 800, A200, A100, A300);
$ccms-accent: mat.define-palette($accent-medium-purple-palette, 900, A200, A100, A300);

// The "warn" palette is optional and defaults to red if not specified.
$ccms-warn: mat.define-palette($warning-medium-red-palette, 700, A200, A100, A300);

$ccms-theme: mat.define-light-theme((
 color: (
   primary: $ccms-primary,
   accent: $ccms-accent,
   warn: $ccms-warn,
 ),
 typography: mat.define-typography-config(),
 density: -3,
));

@include mat.all-component-themes($ccms-theme);

.mat-icon {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}